<template>

  <section class="presentation">

    <div class="presentation-text">

      <h1>Repair and prevention for your hydraulic</h1>

      <h3>The best repair is the one you don't need !</h3>

    </div>

    <div class="call-us">
      <p>Call us :</p>
      <p>+44 7836 204011</p>
      <p>West London</p>
    </div>

    <div class="home-img-container">
      <img src="@/assets/20220413_162215.jpg" alt="hydraulic machine" />
    </div>

  </section>




</template>

<script>

export default {
  name: 'HomeView',

}
</script>

<style lang="scss">
@import '@/styles/index.scss';

.presentation {
  display: flex;
  flex-direction: column;

  .presentation-text {
    text-align: center;
  }

  h1 {
    text-align: center;
    font-size: 32px;
    color: $color-secondary;
  }

  .call-us {
    @include center;
    flex-direction: column;
    font-weight: 700;
  }

  .home-img-container {
    width: 100%;
    @include center;
  }

  img {
    width: 80%;
    border-radius: 10px;
  }


  @include md {
    flex-direction: row;
    justify-content: space-around;

    .call-us {
      width: 30%;
    }

    .home-img-container {
      width: 30%;
    }
  }

  @include lg {
    flex-direction: row;
    justify-content: space-around;

    .call-us {
      width: 30%;
    }

    .home-img-container {
      width: 30%;

    }
  }
}
</style>