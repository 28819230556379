<template>
    <div class="contact-form-container">

        <form ref="form" @submit.prevent="sendForm">

            <div class="form-infos">
                <div class="contact-form-question">
                    <label for="subject">Subject</label>
                    <input type="text" name="subject" id="subject" v-model="subject" placeholder="Subject">
                </div>
                <div class="contact-form-question">
                    <label for="firstName">First Name</label>
                    <input type="text" name="firstName" id="firstName" v-model="firstName" placeholder="First Name *"
                        required>
                </div>
                <div class="contact-form-question">
                    <label for="lastName">Last Name</label>
                    <input type="text" name="lastName" id="lastName" v-model="lastName" placeholder="Last Name *"
                        required>
                </div>
                <div class="contact-form-question">
                    <label for="address">Address</label>
                    <input type="text" name="address" id="address" v-model="address" placeholder="Address">
                </div>
                <div class="contact-form-question">
                    <label for="city">City</label>
                    <input type="text" name="city" id="city" v-model="city" placeholder="City *" required>
                </div>
                <div class="contact-form-question">
                    <label for="phone">Phone Number</label>
                    <input type="text" name="phone" id="phone" v-model="phone" placeholder="Phone number">
                </div>
                <div class="contact-form-question">
                    <label for="email">Email</label>
                    <input type="email" name="email" id="email" v-model="email" placeholder="Email *" required>
                </div>
            </div>
            <div class="form-request">
                <div class="contact-form-question">
                    <label for="text">Your request</label>
                    <textarea name="text" id="text" v-model="text" rows="5" cols="33" placeholder="Your request *"
                        required></textarea>
                </div>
            </div>
            <div class="contact-form-submit">
                <input type="submit" value="Send !" id="send">
            </div>

        </form>

    </div>

    <div class="required-field">
        <p>* : required field</p>
    </div>

</template>

<script>

import emailjs from '@emailjs/browser'

export default {
    name: 'ContactView',

    created() {
        emailjs.init('r3hq0k-RxFaGSmYgx');
    },
    methods: {
        sendForm() {
            console.log("Form sent")
            console.log(this.text)

            this.contactNumber = Math.random() * 100000 | 0;

            emailjs.sendForm('service_fu36d9d', 'contact_form', this.$refs.form)
                .then(function () {
                    console.log('SUCCESS!');
                    alert("Your message has been sent !");
                }, function (error) {
                    alert("An error happend. Please try later or send an email to : info@pierrehydraulic.com");
                    console.log(this);
                    console.log('FAILED...', error);
                });


        }
    }
}
</script>

<style scoped lang="scss">
@import '@/styles/index.scss';

.contact-form-container {

    @include center;
    color: darken($color: $color-secondary, $amount: 10);
    margin-top: 50px;

    form {
        width: 90%;

        .contact-form-question {
            margin-bottom: 10px;
            width: 100%;
            @include center;

            label {
                display: none;
            }

            input {
                width: 100%;
                max-width: 300px;
                height: 25px;
                font-size: 18px;
                color: darken($color: $color-secondary, $amount: 10);
            }
        }

        textarea {
            min-height: 300px;
            width: 100%;
            max-width: 400px;
            line-height: 1.5;
            font-size: 14px;
            color: darken($color: $color-secondary, $amount: 10);
            font-family: 'Sora', sans-serif;
        }

        .contact-form-submit input {
            background-color: darken($color: $color-secondary, $amount: 20);
            color: lighten($color: $color-primary, $amount: 20);
            height: 40px;
            width: 100px;
            border-radius: 30px;
            border: none;
            font-family: 'Sora', sans-serif;

            &:hover {

                transform: scale(1.01);
                box-shadow: 0 3px 5px 0 $color-secondary;
                cursor: pointer;

            }
        }

        @include md {
            display: flex;
            justify-content: space-around;

            .contact-form-question input {
                min-width: 160px;
            }            

            #text {
                width: 300px;
                margin-left: 20px;
                margin-right: 20px;
            }

            .contact-form-submit {
                @include center;
            }
        }

        @include lg {
            display: flex;
            justify-content: space-around;

            .contact-form-question input {
                min-width: 250px;
            } 

            #text {
                width: 400px;
            }

            .contact-form-submit {
                @include center;
            }
        }
    }
}

.required-field {
    margin-right: 30px;
    text-align: right;
}
</style>