<template>
  <div class="about">
    <h1>How we work</h1>

    <article>
      Our hydraulic mechanics company offers a full range of maintenance <br>
    and maintenance services for industrial hydraulic systems. <br>
      We work with the most recognized brands in the field to ensure impeccable service quality. <br>
      We are specially trained and experienced in the repair and maintenance of all types of industrial hydraulic systems, <br>
      whether they are production machines, cranes or forklifts. <br>
      
      We offer regular maintenance contracts to ensure the proper functioning of your equipment and prevent costly breakdowns. <br>      
      We carry out interventions on site.  <br>
      As professionals in hydraulic mechanics, we take great pride in respecting the safety and environmental standards in force in your industry. <br>
      We work meticulously and rigorously to ensure the reliability and durability of our interventions. <br>
      
      <h4>Contact us now to find out more about our maintenance and maintenance services for industrial hydraulic systems and request a free quote.</h4> 
      We will be happy to answer any of your questions and help you find the solution that is right for your needs. <br>
    </article>



    
  </div>
</template>

<script>

export default {
  name: 'AboutView',
 
}
</script>
