<template>

  <header>
    <p>Pierre Hydraulic Services</p>
  </header>
  <nav>
    <router-link to="/">Home</router-link>
    <router-link to="/about">About</router-link>
    <router-link to="/services">Services</router-link>
    <router-link to="/contact">Contact us</router-link>
  </nav>

  <router-view />

  <footer>

    <div class="footer-nav">

      <div class="router-links">
        <h4>Sitemap</h4>
        <router-link to="/">Home</router-link>
        <router-link to="/about">About</router-link>
        <router-link to="/services">Services</router-link>
        <router-link to="/contact">Contact us</router-link>
      </div>

      <div class="contacts">
        <h4>Contact</h4>
        <a href="mailto:info@hydraulic-services.co.uk">info@pierrehydraulic.com</a>
        <div class="mobile">
          <font-awesome-icon icon="fa-solid fa-mobile-screen-button" />
          <p>+44 7836 204011</p>
        </div>
        <p class="location">West London</p>
      </div>

      <div class="hours">
        <h4>Buisness hours</h4>
        <p>Mon - Friday : 8 am to 5 pm</p>
        <p>Weekend : Booked only</p>
      </div>

    </div>

    <div class="legal-mentions">
      <p class>Copyright © 2023 PHS</p>
    </div>


  </footer>
</template>

<script>

export default {

}

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
@import '@/styles/index.scss';

#app {
  font-family: 'Sora', sans-serif;
  color: #2c3e50;
}

header {
  @include center;
  flex-wrap: wrap;
  height: fit-content;
  min-height: 200px;
  background-color: $color-secondary;
  color: lighten($color: $color-primary, $amount: 10);

  p {
    font-size: 48px;
    text-align: center;
    font-family: 'Russo One', sans-serif;

  }

}

nav {
  display: flex;
  flex-wrap: wrap;
  min-height: 50px;
  margin-bottom: 30px;
  height: fit-content;
  @include center;
  background-color: #f0f0f0;

  a {
    font-weight: bold;
    color: $color-tertiary;
    margin: 10px;

    &.router-link-exact-active {
      color: darken($color: $color-secondary, $amount: 5);
    }
  }


  @include md {
    flex-direction: row;
  }
}

.router-links {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 10px;
}


footer {
  margin-top: 50px;
  background-color: darken($color: $color-secondary, $amount: 5);
  height: fit-content;
  min-height: 200px;
  font-size: smaller;
  color: lighten($color: $color-primary, $amount: 20);


  h4 {
    margin-bottom: 10px;
    font-size: small;
  }

  a {
    text-decoration: none;
    color: lighten($color: $color-primary, $amount: 20);
    margin: 5px;

    &.router-link-exact-active {
      color: lighten($color: $color-primary, $amount: 30);
    }
  }

  .footer-nav {
    @include center;
    flex-direction: column;
    text-align: center;

    @include md {
      flex-direction: row;
      justify-content: space-around;
      align-items: baseline;
    }

    @include lg {
      flex-direction: row;
      justify-content: space-around;
      align-items: baseline;

    }

    .mobile {
      @include center;
      text-align: center;

      .fa-mobile-screen-button {
        display: block;
        margin-right: 10px;
      }
    }
  }

  .legal-mentions p {
    text-align: right;
    font-size: x-small;
    margin-right: 10px;
    padding-bottom: 5px;
  }

  .location {
    margin-top: 0px;
  }
}


article {
  margin-left: 30px;
}
</style>
