<template>

    <h1>Preventive maintenance</h1>

    <article>
        <h3>Why is preventive maintenance important for industrial hydraulic systems?</h3>

        Preventive maintenance involves regular interventions on equipment to prevent breakdowns and extend their
        lifespan. <br>
        This approach is particularly important for industrial hydraulic systems,<br>
        which are subjected to high stresses and must be in perfect working order to ensure the production and safety of
        the company.<br>

        <h4>Here are some benefits of preventive maintenance for industrial hydraulic systems: </h4>

        <h5> Time and money savings: </h5>
        performing regular preventive maintenance interventions, <br>
        you can avoid costly breakdowns and production downtimes. <br>
        You can save time and money in the long run.<br>

        <h5>Extension of equipment lifespan:</h5>
        by performing inspections and repairs before a problem arises, <br>
        you can extend the lifespan of your equipment and reduce long-term replacement costs.<br>

        <h5>Improvement of safety: </h5>
        ensuring that your equipment is in perfect working order, <br>
        you can reduce the risk of accidents and injuries on the job.<br>

        As a hydraulic mechanics company, <br>
        we offer complete preventive maintenance contracts to ensure the proper functioning of your industrial hydraulic
        systems. <br>
        <h5>Contact us now to find out more and request a free quote.</h5>
    </article>



</template>


<script>

export default {
    name: 'ServicesView',
}
</script>

<style scoped lang="scss">
h2 {
    text-align: left;
    margin-left: 30px;
}

p {
    text-align: left;
    margin-left: 40px;
}
</style>